(function() {
  var win = window,
    doc = win.document,
    ued = 'undefined' !== typeof win.ued ? win.ued : win.ued = {},
    head = doc.head || doc.getElementsByTagName('head')[0] || doc.documentElement,
    baseElement = head.getElementsByTagName('base')[0],

    APP_URL = '//act.17173.com/weixin/index.php',
    MM_SDK_URL = '//res.wx.qq.com/open/js/jweixin-1.2.0.js',
    DEFAULT_TITLE = doc.title,
    DEFAULT_LINK = win.location.href,
    DEFAULT_IMG = 'https://ue.17173cdn.com/a/module/m/gb/2017/img/logo.png',
    eventStore = {},
    mmSdk = win.wx,
    signature,
    ready,
    singleton;

  //非微信环境则退出
  if (!/MicroMessenger/i.test(navigator.userAgent)) {
    ued.MicroMessengerSdk = function() {};
    return;
  }

  function mixin() {
    var args = Array.prototype.slice.call(arguments, 0),
      target = args.shift(),
      source, p;
    while ((source = args.shift())) {
      for (p in source) {
        target[p] = source[p];
      }
    }
    return target;
  }

  function loadJs(url, callback) {
    var node = doc.createElement('script');
    node.charset = 'utf-8';
    listen(node, callback);
    node.async = true;
    node.src = url;
    baseElement ?
      head.insertBefore(node, baseElement) :
      head.appendChild(node);
  }

  function listen(node, callback) {
    var supportOnload = 'onload' in node;

    function onload() {
      node.onload = node.onerror = node.onreadystatechange = null;
      head.removeChild(node);
      node = null;
      callback && callback();
    }
    if (supportOnload) {
      node.onload = onload;
    } else {
      node.onreadystatechange = function() {
        if (/loaded|complete/.test(node.readyState)) {
          onload();
        }
      };
    }
  }

  function upperCaseup(str) {
    return str.replace(/(^\w)/ig, function(str, first) {
      return first.toUpperCase();
    });
  }

  function MicroMessengerSdk(options) {
    var agent = singleton || this;
    var defaultConfig = agent.options || {
      debug: false,
      title: DEFAULT_TITLE,
      link: DEFAULT_LINK,
      imgUrl: DEFAULT_IMG,
      desc: this.getDescription() || DEFAULT_LINK
    };

    options = agent.options = mixin(defaultConfig, options);

    agent.setShareObject({
      title: options.title,
      link: options.link,
      imgUrl: options.imgUrl,
      desc: options.desc
    });
    singleton = agent;
    return singleton;
  }

  MicroMessengerSdk.prototype = {

    getShareObject: function() {
      return this.shareObject;
    },

    setShareObject: function(object) {
      var self = this;
      self.shareObject = self.shareObject || {};
      mixin(self.shareObject, object);

      if (!self.initEvents) {
        this.registerEvents(this.shareObject);
        self.initEvents = true;
      }

      self.ready(function() {
        mmSdk.onMenuShareTimeline(self.shareObject);
        mmSdk.onMenuShareAppMessage(self.shareObject);
        mmSdk.onMenuShareQQ(self.shareObject);
        mmSdk.onMenuShareQZone(self.shareObject);
        mmSdk.onMenuShareWeibo(self.shareObject);
      });
    },

    /**
     * (shareTimeline|sendAppMessage|shareQQ|shareQZone|shareWeibo)(Success|Fail|Complete|Cancel|trigger)
     */
    /*jshint loopfunc:true */
    registerEvents: function(shareObject) {
      var self = this,
        event,
        events = ['success', 'fail', 'complete', 'cancel', 'trigger'];
      while ((event = events.shift())) {
        shareObject[event] = (function(eventType) {
          return function(res) {
            var shareType = res.errMsg ? res.errMsg.split(':')[0] : '';
            shareType += shareType ? upperCaseup(eventType) : eventType;
            self.fire(shareType, {
              eventType: eventType,
              shareType: shareType,
              original: arguments
            });
          };
        })(event);
      }
    },

    getSdk: function(callback) {
      var self = this,
        done;
      if (!mmSdk) {
        done = function(wx) {
          mmSdk = wx;
          callback && callback.apply(self, mmSdk);
        };
        if (win.seajs) {
          win.seajs.use(MM_SDK_URL, function(wx) {
            done(wx);
          });
        } else {
          loadJs(MM_SDK_URL, function() {
            done(win.wx);
          });
        }
      } else {
        done(mmSdk);
      }
    },

    ready: function(callback) {
      var self = this;
      self.getSdk(function() {
        self.getBaseConfig(function(_signature) {
          self.weiXinReady(_signature, callback);
        });
      });
    },

    weiXinReady: function(_signature, callback) {
      if (!ready) {
        _signature.debug = this.options.debug;
        _signature.jsApiList = [
          'checkJsApi',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareWeibo',
          'onMenuShareQZone',
          'hideMenuItems',
          'showMenuItems',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem',
          'translateVoice',
          'startRecord',
          'stopRecord',
          'onVoiceRecordEnd',
          'playVoice',
          'onVoicePlayEnd',
          'pauseVoice',
          'stopVoice',
          'uploadVoice',
          'downloadVoice',
          'chooseImage',
          'previewImage',
          'uploadImage',
          'downloadImage',
          'getNetworkType',
          'openLocation',
          'getLocation',
          'hideOptionMenu',
          'showOptionMenu',
          'closeWindow',
          'scanQRCode',
          'chooseWXPay',
          'openProductSpecificView',
          'addCard',
          'chooseCard',
          'openCard'
        ];
        signature = _signature;
        mmSdk.config(_signature);
        mmSdk.ready(function() {
          ready = true;
          callback && callback(mmSdk);
        });
      } else {
        callback && callback(mmSdk);
      }
    },

    getBaseConfig: function(callback) {
      if (signature) {
        callback && callback(signature);
      } else {
        this.getSignature(null, function(data) {
          callback && callback(data);
        });
      }
    },

    createAppUrl: function(callbackName, url) {
      return APP_URL +
        '?callback=' + callbackName +
        '&do=weixin' +
        '&url=' + encodeURIComponent(url || location.href.split('#')[0]);
    },

    getSignature: function(url, callback) {
      var callbackName = 'weixin' + Math.random().toString(36).substr(2);
      win[callbackName] = function(data) {
        callback && callback(data);
        setTimeout(function() {
          win[callbackName] = undefined;
        }, 0);
      };
      loadJs(this.createAppUrl(callbackName, url));
    },

    getDescription: function() {
      var metas = head.getElementsByTagName('meta');
      var content;
      for (var i = 0; i < metas.length; i++) {
        if (metas[i].name == 'description') {
          content = metas[i].getAttribute('content');
          if(content){
            return content.replace(/^,/,'');
          }else{
            return null;
          }
        }
      }
      return null;
    },

    on: function(eventType, fn) {
      if (!eventStore[eventType]) {
        eventStore[eventType] = [];
      }
      if (typeof fn === 'function') {
        eventStore[eventType].push(fn);
      }
    },

    //解除绑定
    off: function(eventType, fn) {
      var fnList;
      if (!eventStore[eventType]) {
        return;
      }
      if (!fn) {
        delete eventStore[eventType];
      } else {
        fnList = eventStore[eventType];
        for (var i = 0; i < fnList.length; i++) {
          if (fn === fnList[i]) {
            fnList.splice(i, 1);
          }
        }
      }
    },

    //触发事件
    fire: function(eventType) {
      var fnList = eventStore[eventType];
      var parameter = Array.prototype.slice.call(arguments, 1);
      var returned = true;
      if (!fnList) {
        return false;
      }
      for (var i = 0; i < fnList.length; i++) {
        if (fnList[i].apply(null, parameter) === false) {
          returned = false;
        }
      }
      return returned;
    }

  };

  ued.MicroMessengerSdk = MicroMessengerSdk;

  if (!win.wx && !ued.weixinSdkInit) {
    new MicroMessengerSdk(win.mesConfig);
  }

})();
